<template>
  <validation-observer ref="selectRules">
    <b-row class="pt-2 px-2">
      <b-col
          lg="4"
          md="4"
          sm="12"
      >
        <b-form-group
            label="Campanha"
            label-for="campanha-input"
            label-class="font_size_label"
        >
          <validation-provider
              #default="{ errors }"
              name="campanha"
              rules="required"
          >
            <v-select
                id="select-campanha"
                v-model="params.campaign"
                variant="custom"
                label="description"
                placeholder="Selecione uma unidade operacional"
                :values="campaignSelect"
                :options="campaignSelect"
                @input="onSelectChangeCampaign"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
          lg="4"
          md="4"
          sm="12"
      >
        <b-form-group
            label="Grupo"
            label-for="grupo-input"
            label-class="font_size_label"
        >
          <validation-provider
              #default="{ errors }"
              name="campanha"
              rules="required"
          >
            <v-select
                id="select-grupo"
                v-model="params.group"
                variant="custom"
                label="description"
                placeholder="Selecione um grupo"
                :options="groupSelect"
                @input="onSelectChangeGroup"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Selecione um grupo de empresas para continuar.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
          lg="4"
          md="4"
          sm="12"
      >
          <validation-provider
              #default="{ errors }"
              name="empresa"
          >
            <CompanySelect
                v-model="companySelectValue"
                :empresaSelect="companySelect"
                :disableAll="companyDisabled"
                :loading="false"
            />
          </validation-provider>
      </b-col>
      <b-col
          lg="4"
          md="4"
          sm="12"
      >
        <b-form-group
            label="Unidade Operacional"
            label-for="unidade-operacional-input"
            label-class="font_size_label"
        >
          <validation-provider
              #default="{ errors }"
              name="unidade"
              rules="required"
          >
            <v-select
                id="unidade-operacional-input"
                v-model="params.unit"
                :options="unitSelect"
                label="description"
                placeholder="Selecione uma unidade"
                :disabled="unitDisabled"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Selecione uma unidade operacional para continuar.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center pb-2">
      <b-button
          type="reset"
          class="mr-2 cor_botao"
          variant="outline-primary"
          @click="clearSearch"
      >
        <feather-icon
            icon="XIcon"
            class="mr-50"
        />
        <span class="align-middle">Limpar</span>
      </b-button>
      <b-button
          variant="primary-button"
          @click="redirectToList"
      >
        <feather-icon
            icon="CheckIcon"
            class="mr-50"
        />
        <span class="align-middle">Selecionar</span>
      </b-button>

    </b-row>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from "vue-select";
import {BButton, BCol, BFormGroup, BRow, VBTooltip} from "bootstrap-vue";
import CompanySelect from "@/views/components/custom/empresa/CompanySelect.vue";

export default {
  components: {CompanySelect, BFormGroup, BCol, BRow, BButton, vSelect, ValidationProvider, ValidationObserver},
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    idCampaign: {
      type: Number,
      required: false,
      default: 0
    },
    campanha: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      params: {
        company: null,
        campaign: null,
        group: null,
        unit: null,
      },
      campaignSelect: [],
      companySelect: [],
      unitSelect: [],
      groupSelect: [],
      showEndereco: false,
      companyDisabled: true,
      unitDisabled: true,
      units: [],
      groupUnits: [],
      companySelectValue: {
        empresa: null
      }
    }
  },

  async mounted() {
    await this.loadCampaigns();

    this.campaignSelect.forEach(campaign => {
      if (campaign.idCampaign === this.campanha.idCampaign) {
        this.params.campaign = campaign;
      }
    });

    await this.loadGroups(this.campanha.idCampaign);

    this.$emit('setLoading', false);
  },

  methods: {
    async loadCampaigns() {
      const parameters = {
        situacao: ['Concluída', 'Em andamento'],
      };
      await this.$http.get(this.$api.campanha(), {params: parameters}).then(({data}) => {
        this.campaignSelect = data.map(campaign => ({
          idCampaign: campaign.id_campanha,
          description: campaign.campanha_descricao,
          tipo: campaign.tipo
        }))
      })
    },

    onSelectChangeCompany(company) {
      this.params.company = company;
      this.unitDisabled = true;
      this.params.unit = null;

      if (!company) {
        this.unitSelect = [...this.groupUnits];
        this.unitDisabled = false;
        return;
      }

      const unitsIds = this.getUnitIdsWithAdhesionFromCompanies([company]);

      this.filterUnits(unitsIds, this.groupUnits);

      this.unitDisabled = false;
    },

    async onSelectChangeGroup() {
      this.params.company = null;
      this.companySelectValue.empresa = null;
      this.params.unit = null;
      this.unitDisabled = true;
      this.companyDisabled = true;
      this.unitSelect = [];

      if (!this.params.group) {
        return;
      }

      await this.loadCompanies();

      const unitIds = this.getUnitIdsWithAdhesionFromCompanies(this.companySelect);
      const units = this.getUnitsFromCompanies(unitIds, this.companySelect);

      this.unitSelect = units.map(unit => ({
        idUnit: unit.id_unidade,
        description: unit.descricao,
        idDepartment: unit.id_departamento_unidade,
      }));

      this.groupUnits = [...this.unitSelect];
      this.unitDisabled = false;
    },

    onSelectChangeCampaign(campaign) {
      this.params.group = null;
      this.params.unit = null;
      this.params.company = null;
      this.unitDisabled = true;
      this.companyDisabled = true;

      if (campaign) {
        this.loadGroups(campaign.idCampaign);
      }

      this.$emit('selectCampanha', {
        campaign: campaign,
      });
    },

    async loadGroups(idCampaign) {
      const parameters = {
        idCampanha: idCampaign,
      };
      await this.$http.get(this.$api.adesaoGrupos(), {params: parameters}).then(({data}) => {
        this.groupSelect = data.map(group => ({
          idGroup: group.id_grupo,
          description: group.descricao
        }));
      });
    },

    async loadCompanies() {
      const parameters = {
        id_campanha: this.campanha.idCampaign
      }

      if (this.params.group) {
        parameters['id_grupo'] = this.params.group.idGroup;
      }

      await this.$http.get(this.$api.empresasComAdesao(), {params: parameters}).then(({data}) => {
        this.companySelect = data.map(company => ({
          idCompany: company.id_empresa,
          name: company.nome_empresa,
          unitsWithAdhesion: company.unidades_com_adesao,
          razao_social: company.razao_social,
          nome_empresa: company.nome_empresa,
          documento_formatado: company.documento_formatado,
          documento: company.documento,
          id_empresa: company.id_empresa,
        }));

        this.companyDisabled = false;
      })
    },

    getUnitIdsWithAdhesionFromCompanies(companies) {
      let permittedUnitsIds = companies.flatMap(
          company => company.unitsWithAdhesion.map(
            unit => unit.id_unidade
          )
        );

      permittedUnitsIds = [...new Set(permittedUnitsIds)];

      return permittedUnitsIds;
    },

    getUnitsFromCompanies(unitIds, companies) {
      let units = [];

      const unitsFromCompanies = companies.flatMap(company => company.unitsWithAdhesion);

      unitIds.forEach(unitId => {
        const unit = unitsFromCompanies.find(unitCompany => unitCompany.id_unidade === unitId);

        if (unit) {
          units.push(unit);
        }
      });

      return units;
    },

    filterUnits(permittedUnitsIds, units) {
      this.unitSelect = units.filter(unit => {
        return permittedUnitsIds.includes(unit.idUnit)
      });
    },

    clearSearch() {
      this.$store.commit('vacinacaoState/CLEAR_SEARCH');
      this.params.company = null;
      this.params.group = null;
      this.params.unit = null;
      this.params.campaign = null;

      this.$emit('selectCampanha', {
        campaign: this.params.campaign,
      });
    },

    redirectToList() {
      this.$refs.selectRules.validate().then(success => {
        if (success) {
          this.$router.push({
            name: 'resumo-registro-vacinacao',
            params: {
              campanha: this.params.campaign,
              grupo: this.params.group,
              empresa: this.params.company,
              unidade: this.params.unit,
              baseNacional: true
            },
          });
        }
      })
    },
  },
  watch: {
    'companySelectValue.empresa': function (value) {
      this.onSelectChangeCompany(value);
    }
  }
}
</script>

<style lang="scss">
.radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
  left: 0;
}
</style>