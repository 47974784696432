<template>
  <validation-observer ref="selectRules">
    <b-row class="pt-2 px-2">
      <b-col lg="4" md="4" sm="12">
        <b-form-group
            label="Campanha"
            label-for="campanha-input"
            label-class="font_size_label"
        >
          <validation-provider #default="{ errors }" name="campanha" rules="required">
            <v-select
                id="select-campanha"
                v-model="params.campanha"
                variant="custom"
                label="campanha_descricao"
                placeholder="Selecione uma campanha"
                :values="campanhaOptions"
                :options="campanhaOptions"
                @input="onSelectChangeCampaign"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>


      <b-col lg="4" md="4" sm="12">
        <CompanySelect
            v-model="searchEmpresa"
            :empresaSelect="empresaOptions"
            :disableAll="false"
            :loading="loading.company"
        />
      </b-col>

      <b-col lg="4" md="4" sm="12">
        <b-form-group
            label="Unidade"
            label-for="unidade-operacional-input"
            label-class="font_size_label"
        >
          <validation-provider #default="{ errors }" name="unidade" rules="required">
            <v-select
                id="unidade-operacional-input"
                v-model="params.unidade"
                :options="unidadeOptions"
                label="descricao_unidade"
                placeholder="Selecione uma unidade"
                :clearable="false"
                :disabled="disabled.unidade"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="px-2" v-if="showEndereco">
      <b-col lg="8" md="8" sm="12">
        <b-form-group
            label="Endereço"
            label-for="endereco-input"
            label-class="font_size_label"
        >
          <validation-provider #default="{ errors }" name="endereco" :rules="showEndereco ? 'required' : ''">
            <v-select
                id="select-endereco"
                variant="custom"
                label="id_empresa_endereco"
                placeholder="Selecione um endereço"
                v-model="params.endereco"
                :values="enderecoOptions"
                :options="enderecoOptions"
                :disabled="disabled.endereco"
            >
              <template #option="data">
                  <span>
                      <b>{{ data.descricao }}</b><br/>
                      {{ formatEndereco(data) }}
                  </span>
              </template>
              <template #selected-option="data">
                  <span>
                      <b>{{ data.descricao }}</b><br/>
                      {{ formatEndereco(data) }}
                  </span>
              </template>
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors[0]" class="text-danger">
              Este campo é de preenchimento obrigatório.
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center pb-2">
      <b-button
          type="reset"
          class="mr-2 cor_botao"
          variant="outline-primary"
          @click="clearSearch"
      >
        <feather-icon
            icon="XIcon"
            class="mr-50"
        />
        <span class="align-middle">Limpar</span>
      </b-button>
      <b-button
          variant="primary-button"
          @click="redirectToList"
      >
        <feather-icon
            icon="CheckIcon"
            class="mr-50"
        />
        <span class="align-middle">Selecionar</span>
      </b-button>

    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol, BRow, BFormGroup, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import CompanySelect from '@/views/components/custom/empresa/CompanySelect.vue';

export default {
  title: 'Selecionar empresa',

  components: {
    BCol,
    BRow,
    BFormGroup,
    BButton,
    vSelect,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    CompanySelect
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    campanha: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Vacinação',
          routeName: 'vacinacao-list',
        },
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
      alert: {
        errorMessage: '',
        show: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      required,
      timeID: null,
      filtering: false,
      loadingData: false,
      without: false,
      searchTerm: '',
      disabled: {
        campanha: true,
        empresa: true,
        unidade: true,
        endereco: true,
      },
      params: {
        unidade: null,
        empresa: null,
        campanha: null,
        endereco: null
      },
      unidadeOptions: [],
      empresaOptions: [],
      campanhaOptions: [],
      enderecoOptions: [],
      unidadesComAdesao: [],
      unidadesObrigatorioEndereco: [],
      loading: {
        company: false
      },
      searchEmpresa: {
        empresa: '',
      }
    }
  },

  beforeMount() {
    this.searchCampanha();
    this.searchEmpresaPorCampanha();
  },

  async mounted() {
    this.searchCampanha();

    await Promise.all([
      this.searchEmpresaPorCampanha(),
      this.loadCampaigns()
    ]);

    this.campanhaOptions.forEach(campaign => {
      if (campaign.id_campanha === this.params.campanha.id_campanha) {
        this.params.campanha = campaign;
      }
    });

    this.$emit('setLoading', false)
  },

  methods: {
    searchCampanha() {
      this.disabled.campanha = true
      this.disabled.empresa = true
      this.disabled.unidade = true
      this.disabled.endereco = true

      this.params.campanha = ''
      this.campanhaOptions = []
      this.disabled.endereco = true;
      this.params.endereco = '';
      this.enderecoOptions = [];

      this.params.campanha = {
        id_campanha: this.campanha.id_campanha,
        campanha_descricao: this.campanha.campanha_descricao,
        departamento_uf: this.campanha.departamento_uf,
        tipo: this.campanha.tipo,
      }
      this.campanhaOptions.push(this.params.campanha)
    },

    async searchEmpresaPorCampanha() {
      this.enderecoOptions = [];
      this.disabled.endereco = true;

      await this.$http.get(this.$api.empresasComAdesao(), {
        params: {
          id_campanha: this.campanha.id_campanha
        }
      }).then(({data}) => {
        this.empresaOptions = data
      })
    },

    onSelectChangeCampaign(campaign) {
      if (campaign) {
        this.searchEmpresaPorCampanha();
      }

      this.$emit('selectCampanha', {
        campaign: this.params.campanha,
      });
    },

    async loadCampaigns() {
      const parameters = {
        situacao: ['Concluída', 'Em andamento'],
      };
      await this.$http.get(this.$api.campanha(), {params: parameters}).then(({data}) => {
        this.campanhaOptions = data;
      })
    },

    async searchUnidade() {
      this.disabled.unidade = true
      this.params.unidade = null

      if (this.unidadeOptions.length > 0) {
        this.disabled.unidade = !this.disabled.unidade
        return;
      }

      const parameters = {
        ativo: true,
      }

      await this.$http.get(this.$api.unidade(), {params: parameters}).then(({data}) => {
        this.unidadeOptions = data.filter(unidade => {
          return this.unidadesComAdesao.includes(unidade.id_unidade)
        })
        this.disabled.unidade = !this.disabled.unidade
      })
    },
    async searchEmpresaEndereco() {
      this.disabled.endereco = false;
      this.params.endereco = '';
      this.enderecoOptions = [];
      this.limparValidacoes()
      if (!this.params.empresa || !this.params.campanha || !this.params.unidade) {
        return
      }

      const rota = this.$api.getEmpresaEnderecoComAdesao(
        this.params.empresa.id_empresa, 
        this.params.campanha.id_campanha, 
        this.params.unidade.id_unidade
      );

      await this.$http.get(rota, {params: {campanhaTipo: 'Regional'}}).then(({data}) => {
        this.enderecoOptions = data;
      });
    },
    redirectToList() {
      this.$refs.selectRules.validate().then(success => {
        if (success) {
          this.$router.push({
            name: 'resumo-registro-vacinacao',
            params: this.params,
          });
        }
      })
    },
    clearSearch() {
      this.$emit('clearSearch')
    },
    orderCompanyList(a, b) {
      if (a.nome_empresa.toLowerCase() > b.nome_empresa.toLowerCase()) return 1

      if (a.nome_empresa.toLowerCase() < b.nome_empresa.toLowerCase()) return -1

      return 0
    },
    formatEndereco(endereco) {
      return this.$helpers.formatEndereco(endereco);
    },
    limparValidacoes() {
      this.$refs.selectRules.reset()
    }
  },
  computed: {
    showEndereco() {
      const temCampanha = this.params.campanha != null;
      const temEmpresa = this.params.empresa != null;
      const temUnidade = this.params.unidade != null;
      let unidadeEnderecoObrigatorio = false;
      if (temUnidade) {
        unidadeEnderecoObrigatorio = this.unidadesObrigatorioEndereco.includes(this.params.unidade.id_unidade)
      }

      return temCampanha &&
        temEmpresa &&
        temUnidade &&
        unidadeEnderecoObrigatorio;
    },
    getSearch() {
      return this.params
    },
  },
  watch: {
    'searchEmpresa.empresa': function (empresa = null) {
      this.params.empresa = empresa
    },
    'params.empresa': function (empresa = null) {
      this.unidadesComAdesao = []
      this.unidadeOptions = []
      this.disabled.unidade = true
      this.params.unidade = null
      if (empresa != null) {
        this.unidadesComAdesao = this.params.empresa.unidades_com_adesao.map(unidade => unidade.id_unidade)
        this.unidadesObrigatorioEndereco = this.params.empresa.unidades_deve_selecionar_endereco.flat();
        this.searchUnidade();
      }
    },
    'params.unidade': function () {
      this.searchEmpresaEndereco();
    }
  }
}
</script>
